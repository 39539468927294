@import '/src/assets/scss/mixins/_mixins.scss';

.MaintenanceContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  gap: 112px;
}
.ImageContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .buttonCamara {
    width: 104px;
    height: 26px;
    padding: 6px 8px 6px 8px;
    border-radius: 25px;
    border: 1px;
    gap: 10px;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid #1F65FF;
    font-size: 12px;
    font-weight: 400;
    color: #1F65FF;
    margin: 0 auto;
  }
}
.logoBanza {
  position: absolute;
  top: 9px;
  left: 29px;
  width: 61px;
  height: 18px;
}
.TextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 29%;
}
.Typography {
  text-align: center;
}

.titleTypography {
  font-size: 28px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0.25px;
  color: #1F65FF;
  text-align: left;
  margin: 0 0 24px 0;
}

.descriptionTypography {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: #757576;
  text-align: left;
  margin: 0 0 24px 0;
}
