@import "/src/assets/scss/variables/_variables.scss";
@import "/src/assets/scss/mixins/_mixins.scss";
// @import url("/src/assets/fonts/Roboto-Black.ttf");

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

//JR clases
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

//end

.fadeOut {
  display: none;
  opacity: 0;
  transition: opacity 0.2s;
}

.fadeIn {
  display: auto;
  opacity: 1;
  transition: opacity 0.2s;
}

// Estilo para picker de datepicker
.flatpickr-day.selected {
  background-color: $blue !important;
  border-color: $blue !important;
}

body {
  background-color: $neutral_6;
  margin: 0;

  &.bz-whiteBg {
    background-color: $white;
  }

  * {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
  }

  .loading {
    content: " ";
    height: 100vh;
    background: url(./assets/img/loading.svg) no-repeat center center transparent;
    background-size: 120px 120px;
  }

  // p {
  //   // color: $grey;
  //   // font-size: 16px;
  //   // letter-spacing: 0.29px;
  //   // margin: 0;

  a {
    color: $blue;
    // font-size: 16px;
    // font-style: italic;
    text-decoration: none;
  }

  //   span {
  //     color: $blue;
  //     font-weight: $weight-bold;

  //     &.bz-mail {
  //       font-size: 16px;
  //     }
  //   }
  // }

  // h1 {
  //   color: $blue;
  //   font-size: 30px;
  //   // font-size: 34px;
  //   font-weight: $weight-medium;
  //   margin-bottom: 30px;
  // }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input::placeholder {
    color: $grey;
  }

  .bz-regWarning {
    &--notEnoghtAmount {
      @extend .bz-regWarning;
      margin-bottom: 15px;
      padding-left: 18px;

      span {
        &.bz-error {
          display: flex;
          align-items: center;
          color: $red;
          padding-left: 30px;
          font-size: 12px;

          &--icon {
            @extend .bz-error;
            width: auto;
            height: 20px;
            background: url(./assets/img/iconExclamationR.svg) no-repeat center left transparent;
            padding-left: 30px !important;
          }
        }
      }
    }

    position: relative;

    a {
      color: $blue;
      font-size: 12px;
      padding-left: 20px;
      text-decoration: none;
      position: absolute;
      right: 0;

      &:hover {
        opacity: 0.8;
      }
    }

    span {
      display: block;
      position: absolute;
      // font-weight: $weight-bold;
      height: 16px;
      line-height: 16px;
      padding: 4px 0 10px 4px;

      &:last-of-type {
        padding-bottom: 0;
      }

      &.bz-error {
        color: $red;
        padding-left: 20px;
        font-size: 12px;
        margin-top: -20px;

        &--icon {
          @extend .bz-error;
          background: url(./assets/img/iconExclamationR.svg) no-repeat center left transparent;
          padding-left: 20px;
          font-size: 16px;
          margin-top: 0;
        }
      }

      // &.bz-default {
      //   position: relative;
      //   color: #acaeaf;
      //   font-size: 10px;

      //   &:before {
      //     content: "✔ ";
      //     padding-right: 4px;
      //   }
      // }

      // &.bz-check {
      //   position: relative;
      //   color: $green;
      //   font-size: 10px;

      //   &:before {
      //     content: "✔ ";
      //     padding-right: 4px;
      //   }
      // }
    }
  }

  .bz-regCheckbox {
    margin-bottom: 40px;

    label {
      color: $grey;
      font-size: 16px;
    }

    input {
      position: relative;
    }

    &--Violet {
      @extend .bz-regCheckbox;

      input {
        position: relative;
        accent-color: $softPurple;
        transform: scale(1.2);
      }

      label {
        margin-left: 11px;
        color: $grey;
        font-size: 16px;
      }
    }
  }

  .bz-regContent {
    display: flex;
    height: 100vh;
    padding: 0;
    width: 100%;

    @media (max-width: 1023px) {
      flex-direction: column;
      // height: auto;
    }

    .bz-socialLogin {
      border-top: solid 1px $softGrey;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      margin-bottom: 50px;
      padding-top: 20px;
      text-align: center;
      width: calc(100% - 60px);

      // p {
      //   font-size: 16px;
      //   color: $grey;
      //   margin-bottom: 25px;
      // }

      span {
        display: flex;
        justify-content: space-around;

        a {
          display: block;
          height: 32px;
          width: 32px;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }

    .bz-recoveryNotification {
      margin-bottom: 40px;

      span {
        font-size: 14px;
        color: $grey;
      }

      // p {
      //   color: $black;
      //   font-size: 20px;
      //   margin-bottom: 20px;
      // }

      a {
        cursor: pointer;
        color: $blue;
        font-size: 14px;
        text-decoration: none;
      }

      a.disabled {
        cursor: pointer;
        color: $grey;
        font-size: 14px;
        text-decoration: none;
      }
    }

    .bz-regContentMain {
      display: flex;
      height: 100%;
      position: relative;
      width: 50%;

      @media (max-width: 1023px) {
        flex-direction: column;
        width: 100%;
      }

      &--Full {
        @extend .bz-regContentMain;
        width: 100%;
        background-color: #fafcff;
      }

      &--Left {
        @extend .bz-regContentMain;
        background-color: $blue;
        flex-direction: column;
        position: fixed;

        @media (max-width: 1023px) {
          // height: 200px;
          position: relative;
        }
      }

      &--Right {
        @extend .bz-regContentMain;
        flex-direction: column;
        margin-left: auto;

        @media (max-width: 1023px) {
          margin: 0;
        }

        .bz-regContentBox {
          width: 328px;
        }
      }
    }

    .bz-bg {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;

      

      &--Email {
        @extend .bz-bg;
        background-image: url(./assets/img/bgEmail.png);
      }

      &--EmailValidation {
        display: flex;
        align-items: center;
        justify-content: center;

      }
    }
  }

  .bz-wizardContentBox {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 100px;
    // padding: 50px 0;
    width: calc(100% - 200px);

    @media (max-width: 1023px) {
      width: calc(100% - 40px);
    }

    // h3 {
    //   font-size: 16px;
    //   font-weight: $weight-bold;
    //   color: $darkGrey;
    //   margin: 0;
    //   margin-bottom: 25px;
    // }

    .bz-form {
      &.blue {
        display: flex;
        flex-direction: row;
        min-height: 117px;

        @media (max-width: 1023px) {
          flex-direction: column;
        }

        div {
          display: flex;
          flex-direction: column;

          @media (min-width: 1023px) {
            &.min-input {
              min-width: 328px;

              label:hover {
                opacity: 0.8;
              }
            }
          }

          &+div {
            font-size: 18px;
            letter-spacing: 0.56px;
            margin-left: 20px;
            max-width: 383px;
            padding-top: 30px;

            @media (max-width: 1023px) {
              margin: 0;
              margin-bottom: 25px;
              max-width: none;
              padding: 0;
            }
          }

          label {
            color: $blue;
            position: relative;
            cursor: pointer;

            &::after {
              background: url(./assets/img/iconArrowBlue.svg) no-repeat center center transparent;
              background-size: 100% auto;
              content: "";
              height: 20px;
              position: absolute;
              width: 20px;
            }

            &.invert {
              &::after {
                transform: rotate(180deg);
              }
            }
          }

          input {
            border: solid 2px $blue;
          }
        }
      }

      label {
        display: block;
        font-size: 18px;
        padding-bottom: 10px;
        letter-spacing: 0.56px;
        white-space: nowrap;
        color: $grey;
      }

      input {
        height: 56px;
        border-radius: 5px;
        border: solid 1px $grey;
        line-height: 56px;
        padding: 0 10px;

        // max-width: 306px;
        width: calc(100% - 22px);
        margin-bottom: 25px;
        font-size: 16px;

        &:focus {
          outline: none;
        }

        &.active {
          border-color: $blue;
        }

        @media (max-width: 1023px) {
          width: calc(100% - 20px);
          max-width: none;
        }
      }
    }

    button {
      width: 328px;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    //TODO: SE PUEDE MODULARIZAR ASSA
    .bz-uploadDocument {
      margin-bottom: 40px;

      .bz-uploadDocument-images {
        display: flex;
        flex-direction: row;
        padding-right: 240px;
        margin-bottom: 20px;
        gap: 26px;

        @media (max-width: 1023px) {
          padding-right: 0;
        }

        &--front {
          @extend .bz-uploadDocument-images;
          background: url(./assets/img/Ilus_onboarding_APP_dni_frente.png) no-repeat left 240px top transparent;
          background-size: auto 105px;

          @media (max-width: 550px) {
            background: none;
          }
        }

        &--back {
          @extend .bz-uploadDocument-images;
          background: url(./assets/img/Ilus_onboarding_APP_dni_dorso.png) no-repeat left 240px top transparent;
          background-size: auto 107px;

          @media (max-width: 550px) {
            background: none;
          }
        }

        button {
          background-color: $white;
          border-radius: 5px;
          border: dashed 1px $grey;
          color: $darkGrey;
          display: block;
          font-size: 12px;
          height: 100px;
          line-height: 100px;
          margin-bottom: 20px;
          // margin-right: 20px;
          text-align: center;
          text-decoration: none;
          width: 100px;
          cursor: pointer;

          &.active {
            font-weight: $weight-bold;
          }

          &.disabled {
            opacity: 0.5;
          }
        }

        button:hover {
          border: dashed 1.5px $blue;

          &.disabled {
            border: dashed 1px $grey;
          }
        }

        img {
          border-radius: 5px;
          border: dashed 1px $lightGrey;
          color: $darkGrey;
          display: block;
          font-size: 16px;
          height: 100px;
          width: 100px;
          line-height: 100px;
          margin-bottom: 20px;
          // margin-right: 20px;
          text-align: center;
          text-decoration: none;
        }

        .image-container {
          position: relative;
          float: left;
          border-radius: 5px;

          // // margin-right: 20px;
          // height: 126px;
          // width: 126px;
          // line-height: 126px;
          &--Filled {
            @extend .image-container;
            height: 100px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #90ee9033;
            box-sizing: border-box;
            border: 2px #006400 solid;
          }

          .checked {
            width: 40px;
          }
        }

        .image-container .after {
          background-color: #333333b3;
          border-radius: 5px;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          width: 100px;
          height: 100px;
          border: 1px solid transparent;
          overflow: hidden;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          transition: all 0.5s ease;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }

        .image-container:hover .after {
          opacity: 0.9;

          .icons {
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            transition: all 0.3s ease;
            opacity: 0.5;
            cursor: pointer;
          }

          .icons:hover {
            opacity: 1;
          }
        }
      }

      .bz-uploadDocument-legend {
        @include boxExclamation;
        font-size: 12px;
        color: $mediumLowGrey;
        letter-spacing: 0.37px;
        line-height: 16px;
        max-width: 447px;
      }
    }

    //TODO: SE PUEDE MODULARIZAR
    .bz-uploadSelfie {
      margin: 0;
      // width: 574px;

      @media (max-width: 1023px) {
        margin: 0 auto;
        width: 100%;
      }

      @media (max-width: 550px) {
        padding: 0;
      }

      .bz-uploadSelfie-tabContainer {
        display: flex;
        flex-direction: row;
        margin-bottom: 25px;

        @media (max-width: 1023px) {
          justify-content: space-between;
        }

        div {
          background-color: $white;
          border: solid 1px $grey;
          border-radius: 5px;
          color: $darkGrey;
          cursor: pointer;
          font-size: 16px;
          font-weight: $weight-bold;
          height: 49px;
          line-height: 49px;
          margin-right: 25px;
          text-align: center;
          width: 222px;

          @media (max-width: 1023px) {
            margin: 0;
            width: calc(50% - 10px);
          }

          &.bz-active {
            border-color: $blue;
            color: $blue;
          }
        }
      }

      .bz-uploadSelfie-Wrapper {
        display: flex;
        position: relative;
        align-items: center;
        // height: 220px;

        @media (max-width: 550px) {
          flex-direction: column;
          align-items: center;
        }

        .image-container {
          height: 220px;
        }

        .image-container .after {
          background-color: #333333b3;
          opacity: 0;
          position: relative;
          top: -220px;
          left: 0;
          width: 220px;
          height: 220px;
          border-radius: 50%;
          border: 1px solid transparent;
          overflow: hidden;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          transition: all 0.5s ease;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }

        .image-container:hover .after {
          opacity: 0.9;

          .icons {
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            transition: all 0.3s ease;
            opacity: 0.5;
            cursor: pointer;
          }

          .icons:hover {
            opacity: 1;
          }
        }

        button,
        img {
          background-color: $white;
          border: dotted 1px $grey;
          border-radius: 50%;
          display: flex;
          height: 220px;
          text-align: center;
          align-items: center;
          justify-content: center;
          width: 220px;
          font-size: 16px;
          cursor: pointer;

          @media (max-width: 550px) {
            margin: 0;
          }
        }
        button:hover,
        img:hover {
          opacity: 0.7;
          background-color: $white;
          border-color:3px dashed #78a2ff !important;

          border-radius: 50%;
          display: flex;
          height: 220px;
          text-align: center;
          align-items: center;
          justify-content: center;
          width: 220px;
          font-size: 16px;
          cursor: pointer;

          @media (max-width: 550px) {
            margin: 0;
          }
        }

        .bz-uploadSelfie-image {
          background-color: $white;
          border: dotted 1px $grey;
          border-radius: 50%;
          display: flex;
          height: 220px;
          width: 220px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          @media (max-width: 550px) {
            margin: 0;
            // width: 100%;
          }

          div {
            color: $darkGrey;
            font-size: 14px;
            margin: auto;

            &.bz-fileUpload {
              background: url(./assets/img/iconCameraDisabled.svg) no-repeat center top transparent;
              background-size: 50px auto;
              padding-top: 75px;
              width: 220px;
            }

            a {
              color: $blue;
              display: block;
              font-size: 16px;
              margin-top: 20px;
              text-decoration: none;
            }
          }

          .bz-file-hidden {
            width: 0;
            height: 0;
          }

          .bz-file {
            video {
              width: 100%;
            }

            border-radius: 50%;
            overflow: hidden;
            position: relative;
            height: 220px;

            img {
              display: block;
            }

            .img-nomg {
              margin: 0;
            }

            .bz-selfieOverlay {
              left: 0;
              position: absolute;
              top: 0;
              z-index: 1;
              background-color: transparent;
            }

            @include iconCamRepeat;
          }
        }

        .bz-uploadSelfie-tip {
          @include boxExclamation;
          height: 190px;
          max-width: 447px;
          width: 265px;

          @media (max-width: 1023px) {
            margin-bottom: 25px;
            max-width: none;
            width: calc(100% - 65px);
          }

          ul {
            padding-left: 20px;

            li {
              color: $grey;
              font-size: 16px;
              letter-spacing: 0.53px;
              padding-bottom: 10px;
            }
          }
        }
      }
    }

    .bz-contentBox {
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0px 0px 8px 0px $softGrey;
      margin-bottom: 15px;
      padding: 20px;
      width: calc(100% - 40px);

      &--noBorder {
        @extend .bz-contentBox;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        width: 100%;

        // @media(max-width: 1023px) {
        //     // flex-direction: column;
        //     margin-left: 20px;
        // //     margin-right: 20px;
        //     width: calc(100% - 40px);
        // }
      }

      &--Center {
        @extend .bz-contentBox;
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;

        @media (max-width: 1023px) {
          flex-direction: column;

          width: calc(100% - 40px);
        }

        .bz-col {
          padding: 0 20px;
          width: 50%;

          @media (max-width: 1023px) {
            width: calc(100% - 40px);
          }
        }
      }

      &--CenterNoBorder {
        @extend .bz-contentBox;
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        max-width: 900px;

        padding: 0;
        width: 100%;

        @media (max-width: 1023px) {
          flex-direction: column;
        }

        .bz-col {
          width: 50%;
          padding: 0 20px;

          @media (max-width: 1023px) {
            padding: 0;

            width: 100%;
          }
        }
      }
    }
  }

  .bz-wizardMenu {
    margin-left: 53px;
    margin-top: 107px;
    margin-bottom: auto;
    margin-right: auto;

    @media (max-width: 768px) {
      margin-left: 1.5rem;
    }

    ul {
      counter-reset: my-sec-counter;
      border-left: solid 2px $softGrey;
      margin: 0;
      padding: 0 0 0 40px;

      li {
        list-style-type: none;
        position: relative;

        &.bz-bullet {

          /* Increment "my-sec-counter" by 1 */
          &::before {
            background-color: $white;
            border: solid 1px $neutral_2;
            border-radius: 17px;
            color: $neutral-2;
            content: counter(my-sec-counter);
            counter-increment: my-sec-counter;
            display: block;
            font-size: 20px;
            font-weight: $weight-bold;
            height: 31px;
            left: -58px;
            line-height: 30px;
            position: absolute;
            text-align: center;
            top: -10px;
            width: 31px;
          }

          &.activeBullet {
            a {
              color: $blue;
              margin-bottom: 20px;

              @media (max-width: 525px) {
                font-size: 0px !important;
              }
            }

            ul {
              display: block;
              margin-bottom: 12px;

              li {
                a {
                  color: $neutral-2;
                  margin-bottom: 16px;
                  font-size: 12px;

                  &.activeItem {
                    color: $neutral-0;
                    font-weight: $weight-medium;
                  }
                }

                &:last-child {
                  margin-bottom: 24px;
                }
              }
            }

            &.bz-bullet {
              &::before {
                border: solid 2px $blue;
                color: $blue;
              }
            }
          }

          &.completeBullet {
            &:last-child {
              &::after {
                display: none;
              }
            }

            a {
              color: #005016;

              @media (max-width: 525px) {
                font-size: 0px !important;
              }
            }

            ul {
              display: none;
            }

            &::before {
              background-color: $green;
              background: url(./assets/img/iconCheck.svg) no-repeat $green;
              border: solid 1px $green;
              border-radius: 17px;
              color: transparent;
              content: counter(my-sec-counter);
              counter-increment: my-sec-counter;
              display: block;
              height: 31px;
              left: -58px;
              position: absolute;
              text-align: center;
              top: -9px;
              width: 31px;
            }

            &::after {
              background-color: $green;
              bottom: 0;
              content: "";
              display: block;
              height: calc(100% + 12px);
              left: -43px;
              position: absolute;
              top: 18px;
              width: 4px;
            }
          }
        }

        a {
          color: $neutral_2;
          display: block;
          // line-height: 24px;
          margin-bottom: 24px;
          text-decoration: none;

          @media (max-width: 525px) {
            font-size: 0px !important;
          }
        }

        ul {
          border: none;
          display: none;
          padding: 0;

          li {
            a {
              font-weight: normal;

              &.active {
                color: $neutral_2;
                font-weight: $weight-bold;
              }
            }
          }
        }
      }
    }
  }

  .bz-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100vh;
    padding: 0px 40px 0 110px;
    width: calc(100% - 150px);
    background-color: #F9F9F9;

    // position: fixed; // TODO: Revisar si no rompe todo - Se aplico para no scrolear en oportunidades
    @media (max-width: 1023px) {
      padding: 0 20px;
      width: calc(100% - 40px);
    }

    .bz-contentInfo {
      position: relative;
      align-content: space-between;
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 10vh;
      justify-content: flex-start;

      // h2 {
      //   color: $black;
      //   font-size: 24px;
      //   font-weight: $weight-bold;
      //   letter-spacing: 0.15px;
      //   margin: 0;
      //   padding: 0;
      // }

      a {
        background-size: 7% auto;
        color: $blue;
        font-size: 16px;
        font-weight: $weight-bold;
        margin-left: 10px;
        text-decoration: none;
        cursor: pointer;

        &::before {
          @include Arrow;
          margin-right: 5px;
        }
      }

      .bz-goBack {
        font-size: 12px;
        font-weight: $weight-regular;
        color: #757576;
        display: flex;
        align-items: center;

        &::before {
          @include Arrow;
          background-size: 5px 5px;
          filter: contrast(0%);
          margin: 0 12px;
        }
      }

      // p {
      //   color: $darkGrey;
      //   font-size: 14px;
      //   margin: 0;
      //   margin-left: auto;
      // }
    }

    .bz-contentMain {
      display: flex;
      height: 80vh;
      width: 100%;

      @media (max-width: 1023px) {
        flex-direction: column;
      }

      .bz-contentMainLeft {
        display: flex;
        flex-direction: column;
        width: 34%;

        @media (max-width: 1023px) {
          width: 100%;
        }
      }

      .bz-contentMainRight {
        display: flex;
        height: 100%;
        width: 70%;

        &--autoHeight {
          @extend .bz-contentMainRight;
          display: unset;
          height: auto;
        }

        @media (max-width: 1023px) {
          flex-direction: column;
          width: 100%;
        }

        .bz-contentBox {
          margin-left: 40px;
          position: relative;
          width: calc(50% - 40px);

          @media (max-width: 1023px) {
            margin-left: 0;
            width: calc(100% - 40px);
          }

          @include boxHeader;

          &--Form {
            @extend .bz-contentBox;
            height: 255px;
          }

          &--FormTransfer {
            @extend .bz-contentBox;
            height: 299px;
            border-radius: 20px !important;
          }

          &--Full {
            @extend .bz-contentBox;
            display: flex;
            width: 100%;

            @include boxHeader;

            &--Center {
              @extend .bz-contentBox;
              width: 100%;
              height: 100%;

              div {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                // h2 {
                //   font-size: $font-xmedium;
                //   color: $blackSoft;
                //   font-weight: $weight-medium;
                //   text-align: center;
                // }

                // p {
                //   width: 27ch;
                //   font-size: 16px;
                //   color: $mediumLowGrey;
                //   text-align: center;
                // }
              }
            }
          }

          &--twoThird {
            @extend .bz-contentBox;
            display: flex;
            padding: 0 !important;
            width: 66% !important;
            max-width: 650px;

            @media (max-width: 1023px) {
              width: 100% !important;
              max-width: none;
            }

            @include boxHeader;
          }
        }

        .bz-contentBox-form {
          margin-left: 40px;
          width: calc(50% - 40px);

          @media (max-width: 1023px) {
            margin-left: 0;
            width: calc(100% - 40px);
          }
        }
      }

      .bz-contentMainFull {
        width: 100%;
      }

      .bz-contentBox {
        background-color: $white;
        border-radius: 20px;
        box-shadow: $bs-down;
        margin-bottom: 15px;
        padding: 24px 16px 21px 16px;
        width: calc(100% - 40px);
        // overflow: hidden;  
        &--noPadding {
          @extend .bz-contentBox;
          width: 100%;
          padding: 0;
        }

        &--WithoutPdTop {
          @extend .bz-contentBox;
          padding-top: 0;
        }

        &--noBorder {
          @extend .bz-contentBox;
          background-color: transparent;
          box-shadow: none;
          padding: 0;
          width: 100%;

          // @media(max-width: 1023px) {
          //     // flex-direction: column;
          //     margin-left: 20px;
          // //     margin-right: 20px;
          //     width: calc(100% - 40px);
          // }
        }

        &--Center {
          @extend .bz-contentBox;
          display: flex;
          flex-direction: row;
          margin-left: auto;
          margin-right: auto;
          max-width: 900px;

          @media (max-width: 1023px) {
            flex-direction: column;

            width: calc(100% - 40px);
          }

          .bz-col {
            padding: 0 20px;
            width: 50%;

            &--noPadding {
              @extend .bz-col;
              padding: 0;
            }

            @media (max-width: 1023px) {
              width: 100%;
            }
          }
        }

        &--CenterNoBorder {
          @extend .bz-contentBox;
          background-color: transparent;
          box-shadow: none;
          display: flex;
          flex-direction: row;
          margin-left: auto;
          margin-right: auto;
          max-width: 900px;

          padding: 0;
          width: 100%;

          @media (max-width: 1023px) {
            flex-direction: column;
          }

          .bz-col {
            width: 50%;
            padding: 0 20px;

            @media (max-width: 1023px) {
              padding: 0;

              width: 100%;
            }
          }
        }
      }
    }
  }

  // .bz-bgCurved {
  //     background: url(./assets/img/bgCurved.svg) no-repeat center bottom transparent;
  // }
  .bz-successPage {
    .bz-contentTop {
      height: 357px;
      margin-bottom: 40px;
      margin-top: 40px;

      .bz-contentInfo {
        justify-content: center;
      }
    }

    .bz-content .bz-contentMain {
      height: auto;
    }
  }

  .bz-bgCurvedTop {
    background: url(./assets/img/bgCurvedTop.svg) no-repeat center top transparent;

    .bz-contentTop {
      height: 357px;
      margin-bottom: 50px;

      .bz-contentInfo {
        justify-content: center;
      }
    }

    .bz-content .bz-contentMain {
      height: auto;
    }
  }

  //ESTE CAUSA ERROR
  .bz-myAssets {
    height: calc(100% - 40px);

    .bz-myAssetsContainer {
      height: 100%;
      overflow-y: auto;
      transition: height 5s;

      &--noScroll {
        @extend .bz-myAssetsContainer;
        overflow-y: unset;
      }

      &--LongPadding {
        @extend .bz-myAssetsContainer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;

        >div {
          height: 55%;
        }

       
      }
    }
  }

  .bz-pack {
    // button {
    //   width: 100% !important;
    // }

    .bz-packTopInfo {
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;

      //*** alinear al centro @agus
      justify-content: center;
      align-items: center;

      &--headerContainer {
        @extend .bz-packTopInfo;
        margin-bottom: 0;
        height: 46px;
        background-color: $blue;
        overflow: hidden;
        border-radius: 20px 20px 0px 0px;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 5px;

        span {
          display: flex;
          align-items: center;
        }
      }

      span {
        height: 20px;
        // ajustar margin @agus
        // margin-left: 20px;
        margin-left: 10px;

        width: 20px;
      }

      a {
        margin-left: 20px;
      }
    }

    .bz-packAsset {
      background-color: $lightGrey;
      margin: 0 -20px 20px;
      padding: 23px 20px 24px 130px;
      position: relative;

      &--noMargin {
        @extend .bz-packAsset;
        margin: 0;
        padding: 17px 15px 16px 130px;
      }

      &--OnTop {
        @extend .bz-packAsset;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: 17px 15px 16px 130px;
        margin: 0 -16px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      img {
        height: 80px;
        left: 20px;
        position: absolute;
        top: calc(50% - 40px);
        width: 80px;
      }

      .bz-packAsset-topInfo {
        display: flex;
        flex-direction: row;

        span {
          color: $blackSoft;
          font-size: 16px;
          font-weight: $weight-medium;
          letter-spacing: 0.18px;
          // reajusto line-height @agus
          // line-height: 20px;
          line-height: 24px;
          margin-bottom: 2px;
          height: auto;

          &+span {
            margin-left: auto;

            // agrego tamaños fijos para los iconos @agus
            //cambio su posicion absoluta por defecto a una mas conveniente @agus
            img {
              margin-right: 16px !important;
              cursor: pointer;
              position: unset;
              width: 20px;
              height: 20px;
            }

            &+a {
              position: unset;
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      // .bz-packAsset-itemValue {
      //   font-size: 16px;
      //   margin-bottom: 12px;
      //   color: $mediumGrey;
      // }

      .bz-packAsset-bottomInfo {
        color: $white;
        font-size: 16px;
      }

      .bz-amount {
        font-size: 16px;
        color: $mediumGrey;
        letter-spacing: 0.14px;
      }

      @include Pill;

      .bz-pill {
        font-size: 10px;
        font-weight: $weight-medium;
        letter-spacing: 1.5px;
      }
    }

    .bz-packBottomInfo {
      padding: 20px;
    }

    .bz-packAssetAmount {
      color: $blue;
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: $weight-medium;
      letter-spacing: 0.15px;
      margin-bottom: 25px;

      div {
        background-color: $lightGrey;
        border-radius: 20px;
        color: $grey;
        display: flex;
        font-size: 14px;
        height: 28px;
        line-height: 40px;
        justify-content: space-around;
        // reajuste margin-right
        margin-right: 25px;
        width: 100px;

        a {
          // cambio de signos a svg @agus
          display: flex;
          align-items: center;
          justify-content: center;

          // color: $blackSoft;
          // letter-spacing: 0.1px;
          // font-size: 2rem;
          // font-weight: $weight-bold;
          // text-align: center;
          text-decoration: none;
          width: 24px;
          cursor: pointer;
        }

        span {
          background-color: $white;
          text-align: center;
          min-width: 30px;
          font-size: 14px;
          color: #191a1b;
          font-weight: $weight-bold;
          align-self: center;
        }
      }
    }
  }

  .bz-packExtra {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .bz-packExtraLink {
      line-height: 17px;
      font-size: 14px;
      color: $purple;
      letter-spacing: 1.25px;
      text-decoration: none;
      font-weight: $weight-bold;
      display: flex;

      &--Back {
        @extend .bz-packExtraLink;
        cursor: pointer;

        &::before {
          background: url(./assets/img/iconArrowPurple.svg) no-repeat center center transparent;
          content: "";
          height: 17px;
          margin-right: 10px;
          transform: rotate(180deg);
          width: 17px;
        }
      }

      &--Continue {
        @extend .bz-packExtraLink;
        cursor: pointer;

        &::after {
          background: url(./assets/img/iconArrowPurple.svg) no-repeat center center transparent;
          content: "";
          height: 17px;
          margin-left: 10px;
          width: 17px;
        }
      }
    }
  }

  //ESTE SE PODRÍA PASAR AL MODULE
  .bz-walletUnits {
    display: flex;
    margin-bottom: 25px;
    position: relative;

    a {
      min-width: calc(7ch + 10px);
      color: $primary_1;
      font-size: 16px;
      font-weight: $weight-regular;
      margin-left: 10px;
      text-decoration: none;
      //cursor pointer@agus
      cursor: pointer;

      &::after {
        margin-left: 5px;
        transform: rotate(180deg);
        cursor: pointer;
        height: 10px;
        width: 10px;
        content: "";
        display: inline-block;
        background-color: #1d63ff;
        -webkit-mask-image: url(./assets/img/iconArrowL.svg);
        mask-image: url(./assets/img/iconArrowL.svg);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
      }
    }

    // flecha opuesta para el "goback" @agus
    &--RowBack {
      margin-bottom: 25px;

      a {
        color: #757576;
        font-size: 16px;
        font-weight: $weight-regular;
        margin-left: 10px;
        text-decoration: none;
        //cursor pointer@agus
        cursor: pointer;
      }

      &::before {
        @include Arrow;
        filter: invert(0.5);
        margin-left: 5px;
        transform: rotate(0);
        cursor: pointer;
        height: 10px;
        background-size: 4px auto;
        width: 10px;
      }
    }
  }

  //ESTE SE PODRÍA PASAR AL MODULE: ???
  .bz-contentHeader {
    text-align: center;
    margin-bottom: 40px;

    // h2 {
    //   color: $blue;
    //   font-weight: $weight-medium;
    //   font-size: 35px;
    //   margin: 0 0 10px 0;
    //   letter-spacing: 0.26px;
    // }

    // p {
    //   margin: 0;
    //   color: $darkGrey;
    //   letter-spacing: 0.14px;
    //   font-size: 18px;
    // }

    // h3 {
    //   color: $blue;
    //   font-weight: $weight-bold;
    //   font-size: 24px;
    //   margin: 0 0 10px 0;
    //   letter-spacing: 0.18px;
    // }

    .bz-subtitleHeader {
      font-size: 14px;
      color: $mediumGrey;
      letter-spacing: 0.25px;
    }
  }

  //ESTE SE PODRÍA PASAR AL MODULE
  .bz-footerwarning {
    @include boxExclamationFooter;
    margin: 0 auto 40px;
    max-width: 520px;
    font-size: 16px;
    color: $mediumGrey;
    padding: 17px 19px 14px 51px;

    @media (max-width: 1023px) {
      margin: 0 0 20px;
      max-width: none;
    }
  }

  .bz-imgBox {
    max-width: 500px;
    margin: auto;
    text-align: center;

    // h2 {
    //   font-size: 24px;
    //   color: $darkGrey !important;
    //   letter-spacing: 0.15px;
    //   margin-bottom: 15px !important;
    // }

    button {
      margin: 30px 0 0 0;
    }

    img {
      margin-bottom: 50px;
    }

    &--noInvestment {
      @extend .bz-imgBox;
      background: url(./assets/img/picNoInvestment.svg) no-repeat center top 100px transparent;
    }

    &--onboarding {
      @extend .bz-imgBox;
      display: flex;
      flex-direction: column;
      align-items: center;
      // h2 {
      //   font-size: 18px !important;
      // }
    }
  }

  .bz-validation {
    height: 59px;
    max-width: 345px;
    border-radius: 5px;
    border: solid 1px $green;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 12px;
    overflow: hidden;
    position: relative;
    background-color: white;

    &::before {
      background-image: url(./assets/img/check-circle-white.svg);
      background-repeat: no-repeat;
      background-position: center left 23px;
      background-color: $green;
      height: 70px;
      width: 70px;
      position: absolute;
      content: "";
      z-index: 2;
    }

    div {
      font-size: 14px;
      font-weight: $weight-medium;
      letter-spacing: 0.43px;
      color: $neutral-1;
      margin-bottom: 5px;
    }

    p {
      display: block;
      // color: $grey-onboarding;
      letter-spacing: 0.08px;
      font-size: 12px;

      a {
        font-size: 12px;
        text-decoration: none;
        letter-spacing: 0.08px;
        color: $blue;
      }
    }

    &--error {
      @extend .bz-validation;
      border-color: $red;

      &::before {
        background-color: $red;
        background-image: url(./assets/img/alert-circl-fulle.svg);
        background-repeat: no-repeat;
        background-position: center left 23px;
      }

    }
  }

  //ESTE SE PODRÍA PASAR AL MODULE
  .bz-investorTest {
    margin-bottom: 25px;

    .bz-lineBox {
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0px 0px 8px 0px $softGrey;
      color: $blackSoft;
      font-size: 12px;
      min-height: 20px;
      line-height: 20px;
      margin-bottom: 12px;
      padding: 20px 20px 20px 20px;
      position: relative;
      width: calc(100% - 70px);
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bz-lineBoxSvg {
        fill: $blackSoft;
      }

      &:hover {

        // opacity: 0.5;
        p {
          color: $primary_1;
        }

        .bz-lineBoxSvg {
          fill: $primary_1;
        }
      }

      a {
        background: url(./assets/img/iconArrowPurple.svg) no-repeat center center transparent;
        display: block;
        filter: saturate(0);
        height: 20px;
        opacity: 0.7;
        position: absolute;
        right: 20px;
        top: calc(50% - 10px);
        width: 20px;
      }
    }

    .bz-lineBox:last-of-type {
      margin-bottom: 24px;
    }
  }
}

#foreground-veil {
  transition: opacity 1s;
  cursor: pointer;
}

.bz-foreground {
  background: $softGrey;
  width: 100%;
  top: 0;
  height: 100%;
  position: fixed;
  transition: opacity 1s;
  opacity: 1;
  z-index:10;
}

//ESTE SE PODRÍA PASAR AL MODULE
.pages-indicator {
  letter-spacing: 0.4px;
  color: #8e8f90;
  font-size: 12px;
  line-height: 16px;
  right: 0;
}

.popover-button {
  letter-spacing: 1.25px;
  color: #8e8f90;
  text-transform: uppercase;
  line-height: 16px;
  font-size: 14px;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline-color: transparent;

  &.next {
    color: #0047e4;
  }

  &:hover {
    opacity: 0.8;
    font-weight: $weight-bold;
  }

  &:active {
    opacity: 0.4;
  }

  &:focus-visible {}
}

.popover-content {
  display: flex;

  .avatar {
    width: 50px;
    height: 40px;
    background-color: #4ca5fe;
    border-radius: 50px;
    margin-right: 10px;
  }

  .info {
    .title {
      letter-spacing: 0.15px;
      font-size: 20px;
      line-height: 24px;
      color: $neutral-0;
      font-weight: $weight-bold; // Roboto MEDIUM
    }

    .description {
      letter-spacing: 0.15px;
      font-size: 14px;
      line-height: 20px;
      color: #515252;
    }
  }
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #78a2ff #d2e0ff;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #d2e0ff;
}

*::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: #78a2ff;
  border-radius: 10px;
  border: 1px solid #78a2ff;
}

.bz-sliderWrapperOpp {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  &--noOverflow {
    @extend .bz-sliderWrapperOpp;
    overflow-y: unset;
    overflow-x: unset;
  }

  .bz-slider {}

  &:last-child {
    margin-bottom: 50px;
  }
}

.bz-sliderItem {
  background-color: rgba($white, 0.1);
  border-radius: 10px;
  box-shadow: $bs-down;
  // margin-bottom: 42px;
  // margin-right: 20px;
  min-width: 168px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  width: 168px !important;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.8;
  }

  img {
    display: block;
    object-fit: cover;
    width: 168px;
    height: 112px;
  }

  span {
    color: $blackSoft;
  }

  .bz-sliderItemCurrency {
    border-radius: 13px;
    color: $white;
    display: block;
    font-size: 10px;
    letter-spacing: 1.5px;
    height: 21px;
    line-height: 21px;
    text-align: center;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 35px;

    &--Blue {
      @extend .bz-sliderItemCurrency;
      background-color: #4b83ff;
    }

    &--Green {
      @extend .bz-sliderItemCurrency;
      background-color: #23ce51;
    }
  }

  .bz-sliderItemText {
    box-sizing: border-box;
    background-color: $white;
    line-height: 22px;
    padding: 12px;
    height: 95px;
    @include Pill;
  }
}


.bz-sliderWrapper {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  .bz-slider {

    .bz-sliderItem {
      background-color: rgba($white, 0.1);
      border-radius: 10px;
      box-shadow: $bs-down;
      margin-bottom: 42px;
      // margin-right: 20px;
      min-width: 200px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      width: 200px !important;
      transition: opacity 150ms;

      &:hover {
        opacity: 0.8;
      }

      img {
        display: block;
        object-fit: cover;
        width: 200px;
        height: 112px;
      }

      span {
        color: $blackSoft;
      }

      .bz-sliderItemCurrency {
        border-radius: 13px;
        color: $white;
        display: block;
        font-size: 10px;
        letter-spacing: 1.5px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        position: absolute;
        right: 8px;
        top: 8px;
        width: 35px;

        &--Blue {
          @extend .bz-sliderItemCurrency;
          background-color: #4b83ff;
        }

        &--Green {
          @extend .bz-sliderItemCurrency;
          background-color: #23ce51;
        }
      }

      .bz-sliderItemText {
        background-color: $white;
        line-height: 22px;
        padding: 14px 12px 16px 12px;

        @include Pill;
      }
    }
  }

  &:last-child {
    margin-bottom: 50px;
  }
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.miswiper {
  // overflow: inherit !important;
  padding: 5px 0 !important;

  .swiper-slide {
    flex-shrink: 1;
    width: auto;
  }
}

.cardListSlider {
  .swiper-slide {
    flex-shrink: 1;
    width: auto;
  }
}

.prueba {
  background-color: red;
}

.ReactVirtualized__Grid {
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: #E4E4E4;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #707070;
    border-radius: 10px;
    border: 1px solid #707070;
  }
}

.apexcharts-text,
.apexcharts-xaxis-label {
  tspan {
    font-size: 12px;
    font-family: "Roboto", sans-serif;
  }
}

.bz-fielsetWrapper {
  margin-bottom: 10px !important;
 
  fieldset {
    border-radius: 25px;
    border: solid 1px $neutral-3;
    color: $neutral-3;
    margin-top: 0;
    margin-bottom: 10px;
    position: relative;
    &.active {
      color: $blue;
      border: solid 1px $blue;
    }

    &.error {
      border: solid 1px $red;
      color: $red;

      input {
        color: $red;
      }
    }

    legend {
      padding: 0 3px;
      font-size: 12px;
    }

    input {
      border: none;
      font-size: 14px;
      height: 23px;
      width: calc(100% - 30px);

      &:focus {
        outline: none;
      }
    }
  }
}

.swiper-button-next{
  // width: 28px;
  // height: 28px;
  // border-radius: 100%;
  background-image: url(./assets/img/VectorRightHomeCard.svg);
  background-repeat: no-repeat;
  // background-size: 100% auto;
  background-position: center;
  background-color: #D2E0FF;
  width: 28px;
  max-height: 28px;
  height: 28px;
  border-radius: 100%;
  top:60% !important; 
  right: 0px !important;
}
.swiper-button-next::after{
  display: none;
}
.swiper-button-next{
  // width: 28px;
  // height: 28px;
  // border-radius: 100%;
  background-color: #D2E0FF;
  width: 28px;
  max-height: 28px;
  height: 28px;
  border-radius: 100%;
  top:60% !important; 
  right: 0px !important;
  padding-left: 1px;

}
.swiper-button-prev{
  background-image: url(./assets/img/VectorLeftHomeCard.svg);
  background-repeat: no-repeat;
  // background-size: 100% auto;
  background-position: center;
  background-color: #D2E0FF;
  background-color: #D2E0FF;
  width: 28px;
  max-height: 28px;
  height: 28px;
  border-radius: 100%;
  top:60% !important; 
  left: 0 !important;
  padding-right: 1px;
}
.swiper-button-prev::after{
  display: none;
}


.pac-container{
  box-shadow: none;
  background: #fff;
  border-top: none;
}

.pac-container::after{
  display: none;
}

.pac-item{
  border-top: none;
  border-bottom: 1px solid #707070;
  margin: 10px 4px;
}

.pac-icon-marker{
  display: none;
}

.pac-matched {
  cursor: default;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  font-size: 16px;
  color: #515151;
  font-weight: 400;
}

.pac-item-query{
  margin: 10px 0;
  cursor: default;
  white-space: nowrap;
  line-height: 30px;
  text-align: left;
  font-size: 16px;
  color: #515151;
}