.bz-containerNotice {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100dvh;
  background-image: url("../../../assets/img/bottom-bg.svg");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: #fff;
  overflow: hidden;
  position: relative;
  .bz-contentNotice {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5.5rem;
    .bz-descriptionContainerNotice {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      width: 90%;
      .bz-descriptionTitle {
        color: #1f65ff;
        text-align: center;
        font-size: 2.375rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.341px;
        margin: 0;
        padding: 0;
      }
      .bz-description {
        color: #757576;
        text-align: center;
        font-size: 1.364rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.205px;
        margin: 0;
        padding: 0 1rem;
        width: 45%;
      }
    }
    .bz-qrContainerNotice {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .bz-qrImage {
        width: 200px;
        height: 200px;
      }
      .bz-buttonCamera {
        padding: 0.512rem 0.683rem;
        border-radius: 2.125rem;
        border: 1px solid #1f65ff;
        gap: 0.625rem;
        background: transparent;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: var(--Primarios-1F65FF, #1f65ff);
        font-family: Roboto;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.546px;
        color: #1f65ff;
        margin: 0 auto;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  .bz-storeImagesContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    .bz-androidImgContainer,
    .bz-iosImgContainer {
      width: 130px;
      .bz-androidImage,
      .bz-iosImage {
        max-width: 100%;
        height: 100%;
      }
    }
  }
}
@media (max-width: 768px) {
  .bz-containerNotice {
    .bz-contentNotice {
      .bz-descriptionContainerNotice {
        .bz-descriptionTitle {
          font-size: 1.75rem;
        }
        .bz-description {
          font-size: 1rem;
          padding: 0 0.5rem;
          width: 100%;
        }
      }
      .bz-qrContainerNotice {
        .bz-buttonCamera {
          padding: 0.256rem 0.341rem;
          font-size: 0.75rem;
        }
      }
    }
  }
}
@media (max-height: 768px) {
  .bz-containerNotice {
    .bz-contentNotice {
      gap: 1rem;
    }
  }
}
